import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import Layout from "../components/layout";

export default () => {
	return (
		<Layout>
			<main>
				<Helmet>
					<title>Anti-spam policy</title>
				</Helmet>

				<header className="aboveFold">
					<div className="container">
						<h1>Anti-spam policy</h1>
					</div>
				</header>

				<section className="container container--text">
					<h2>We have no tolerance towards spam</h2>
					<p className="u-mb4">
						We trust you, and we assume, you'll only ever send emails to
						recipients who opted-in to receive emails from you. For example, via
						subscribe form on your blog to receive updates when a new article
						comes out. Do not import contacts to Sidemail that you found on the
						internet, scraped or bought — that's a no go.
					</p>

					<h2>You can't send cold emails</h2>
					<p className="u-mb4">
						You must you a different service than Sidemail to send out cold
						emails. Sending out emails to recipients who have never opted-in to
						receive emails from you is against this policy.
					</p>

					<h2>Sending reputation</h2>
					<p className="u-mb4">
						We require your account's bounce rate to remain below 7%, and the
						spam complaint rate below 0.1%. For Sidemail accounts with a low
						amount of historically sent emails, we have a slightly different
						calculation method to prevent rare cases such as when there's a
						bounce or complaint in just a few emails sent.
					</p>

					<h2>Account suspension</h2>
					<p className="u-mb4">
						We'd hate to go down this route, but if we discover that a Sidemail
						user attempts to send out spam, or otherwise breaches this policy,
						we'll immediately suspend his account.
					</p>

					<p>
						Questions? <Link to="/contact/">Get in touch</Link>.
					</p>
				</section>
			</main>
		</Layout>
	);
};
